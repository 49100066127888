@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
}

p {
  padding: 0;
  margin: 0;
}

body {
  background-color: #ffffff;
  /* min-width: 470px; */
}
